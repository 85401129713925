<template>
  <div class="flex flex-col font-black lg:flex-row lg:flex-wrap">
    <div class="flex flex-wrap justify-center lg:justify-start">
      <span>
        {{
          articleInfo.categories.length === 1
            ? "Kategorie:&nbsp;"
            : "Kategorien:&nbsp;"
        }}
      </span>

      <span v-for="(category, index) in articleInfo.categories" :key="index">
        <span
          class="cursor-pointer underline"
          @click="setCategory(category)"
          v-html="category.name"
        />
        <span v-if="pretendComma(index)" v-html="'&nbsp'" />
        <span v-else-if="appendComma(index)" v-html="',&nbsp'" />
      </span>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { ArticleInfo } from "~~/types/magazin";

const props = defineProps<{
  articleInfo: ArticleInfo;
}>();

function setCategory(category) {
  magazinFilter.value.category = {
    label: category.name,
    value: category.category_id,
  };
}
const pretendComma = (index) => {
  return (
    props.articleInfo.categories.length === 1 ||
    index === props.articleInfo.categories.length - 1
  );
};

const appendComma = (index) => {
  return (
    props.articleInfo.categories.length > 1 ||
    index < props.articleInfo.categories.length - 1
  );
};
</script>
